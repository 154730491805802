import { useContext, useState, useMemo } from 'react';
import {
    Flex,
    Stack,
    ListItem,
    Avatar,
    Text,
    Tag,
    Spacer,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,
    Box,
    Image,
    Modal,
    ModalOverlay, ModalContent, ModalCloseButton, Icon, useMediaQuery, Portal, Spinner, VStack, Button, IconButton, Tooltip
} from '@chakra-ui/react';
import './profileMain.css'
import { FiTrash2, FiMoreHorizontal, FiTrendingDown, FiTrendingUp, FiUnlock, FiLock, FiActivity, FiSend, FiEye, FiCheckCircle, FiXCircle, FiMinusCircle, FiFlag, FiCopy, FiClock, FiPercent } from 'react-icons/fi'

import { differenceInDays, differenceInDaysClose } from "../util/DateUtils"
import "./profileMain.css"
import { Link } from 'react-router-dom';
import { AuthContext, GlobalContext } from "../App.js"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { formatLargeNumber } from '../util/NumberUtils';
import { ChatIcon, TriangleUpIcon } from '@chakra-ui/icons';

import ReplyModal from "../Reply/ReplyModal"
import { getLocal } from '../util/GetLocal';
import { MdVerified } from "react-icons/md";

const Posts = ({ postItem, fromPostPage, setReply, replies }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [closeLoading, setCloseLoading] = useState(false)
    const [averageLoading, setAverageLoading] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [likeLoading, setLikeLoading] = useState(false)
    const [isSmallScreen] = useMediaQuery("(max-width: 820px)");

    const [authData, setAuthData] = useContext(AuthContext)
    const [globalData, setGlobalData] = useContext(GlobalContext)

    const [isDeleted, setIsDeleted] = useState(false)
    const [replyModal, setReplyModal] = useState(false)

    const closeState = {
        "Win": {
            color: "green",
            icon: FiCheckCircle
        },
        "Loss": {
            color: "red",
            icon: FiXCircle
        },
        "Neutral": {
            color: "gray",
            icon: FiMinusCircle
        }
    }

    const closePost = async () => {
        setCloseLoading(true)
        let response
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/closepost`,
                {
                    method: 'POST',
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    },
                    body: JSON.stringify({
                        post_id: postItem.post_id
                    })
                })
        }
        catch (e) {
            console.error(e)
            toast.error("Failed to close post, please try again", {
                autoClose: 2000,
                hideProgressBar: true
            })
            setCloseLoading(false)
            return
        }
        if (!response.ok) {
            const error = await response.json()
            console.log(error)
            toast.error(error.message, {
                autoClose: 2000,
                hideProgressBar: true
            })
        }
        else {
            const data = await response.json()

            let posts = globalData.allPosts;
            //since only auth user can close their own posts, and their own posts only show up on the profilemain page no need to 
            //loop through all the keys (each dif page like /feed) and update the post their too bc their post won't ever show up there

            if (globalData.allPosts[authData.firebaseuser?.displayName]) {
                for (let i = 0; i < posts[authData.firebaseuser.displayName].length; i++) {
                    if (posts[authData.firebaseuser.displayName][i].post_id === postItem.post_id) {
                        posts[authData.firebaseuser.displayName][i].is_open = data.data[0].is_open
                        posts[authData.firebaseuser.displayName][i].is_closed = data.data[0].is_closed
                        posts[authData.firebaseuser.displayName][i].close_price = data.data[0].close_price
                        posts[authData.firebaseuser.displayName][i].closed_percent = data.data[0].closed_percent
                        posts[authData.firebaseuser.displayName][i].closed_at = data.data[0].closed_at
                        posts[authData.firebaseuser.displayName][i].close_state = data.data[0].close_state

                    }
                }
            }

            if (fromPostPage) { //so that when we close from post page the UI of the post from post page component gets updated
                postItem.is_open = data.data[0].is_open
                postItem.is_closed = data.data[0].is_closed
                postItem.close_price = data.data[0].close_price
                postItem.closed_percent = data.data[0].closed_percent
                postItem.closed_at = data.data[0].closed_at
                postItem.close_state = data.data[0].close_state
            }
            setGlobalData({ ...globalData, allPosts: posts })
        }
        setCloseLoading(false)

    }

    const averagePrice = async () => {
        setAverageLoading(true)
        let response
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/averagepost`,
                {
                    method: 'POST',
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    },
                    body: JSON.stringify({
                        post_id: postItem.post_id
                    })
                })
        }
        catch (e) {
            console.error(e)
            toast.error("Failed to average up/down post, please try again", {
                autoClose: 2000,
                hideProgressBar: true
            })
            setAverageLoading(false)
            return
        }
        if (!response.ok) {
            const error = await response.json()
            console.log(error)
            toast.error(error.message, {
                autoClose: 2000,
                hideProgressBar: true
            })
        }
        else {
            const data = await response.json()
            let posts = globalData.allPosts;
            if (globalData.allPosts[authData.firebaseuser?.displayName]) {
                for (let i = 0; i < posts[authData.firebaseuser.displayName].length; i++) {
                    if (posts[authData.firebaseuser.displayName][i].post_id === postItem.post_id) {
                        posts[authData.firebaseuser.displayName][i].alert_price = data.data[0].alert_price
                        posts[authData.firebaseuser.displayName][i].is_open = data.data[0].is_open
                        posts[authData.firebaseuser.displayName][i].is_closed = data.data[0].is_closed
                        posts[authData.firebaseuser.displayName][i].close_price = data.data[0].close_price
                        posts[authData.firebaseuser.displayName][i].closed_percent = data.data[0].closed_percent
                        posts[authData.firebaseuser.displayName][i].closed_at = data.data[0].closed_at
                        posts[authData.firebaseuser.displayName][i].close_state = data.data[0].close_state

                    }
                }
            }
            if (fromPostPage) { //so that when we close from post page the UI of the post from post page component gets updated
                postItem.alert_price = data.data[0].alert_price
                postItem.is_open = data.data[0].is_open
                postItem.is_closed = data.data[0].is_closed
                postItem.close_price = data.data[0].close_price
                postItem.closed_percent = data.data[0].closed_percent
                postItem.closed_at = data.data[0].closed_at
                postItem.close_state = data.data[0].close_state
            }
            setGlobalData({ ...globalData, allPosts: posts })
        }
        setAverageLoading(false)
    }

    const likePost = async () => {
        setLikeLoading(true)
        let httpMethod = "POST"
        if (postItem.auth_user_likes !== 0) {
            httpMethod = "DELETE"
        }
        if (!authData.firebaseuser) {
            toast.error("Must be signed in to upvote posts", {
                autoClose: 2000,
                hideProgressBar: true
            })
            setLikeLoading(false)
            return
        }
        let response
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/likepost`,
                {
                    method: httpMethod,
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    },
                    body: JSON.stringify({
                        post_id: postItem.post_id
                    })
                })
        }
        catch (e) {
            console.error(e)
            toast.error(httpMethod === "POST" ? "Failed to upvote post" : "Failed to downvote post", {
                autoClose: 2000,
                hideProgressBar: true
            })
            setLikeLoading(false)
        }

        if (!response.ok) {
            const error = await response.json()
            console.error(error)
            toast.error(error.message, {
                autoClose: 2000,
                hideProgressBar: true
            })
            setLikeLoading(false)
        }
        else {
            const data = await response.json()

            let posts = globalData.allPosts
            let postIDHasBeenUpdated = new Set()

            for (let key in posts) {
                for (let i = 0; i < posts[key].length; i++) {
                    //so we want to update the likes on the post check if current post id matches whats in globalData.allPosts and only update
                    //if that post hasn't already been updated
                    if (posts[key][i].post_id === postItem.post_id && !postIDHasBeenUpdated.has(posts[key][i].post_id)) {
                        if (httpMethod === "POST") {
                            posts[key][i].like_count++
                            posts[key][i].auth_user_likes = 1;
                            postIDHasBeenUpdated.add(posts[key][i].post_id)
                        }
                        else {
                            posts[key][i].like_count--
                            posts[key][i].auth_user_likes = 0;
                            postIDHasBeenUpdated.add(posts[key][i].post_id)
                        }

                    }

                }
            }

            if (fromPostPage && !postIDHasBeenUpdated.has(postItem.post_id)) { //so that when we like from post page the UI of the post from post page component gets updated
                if (httpMethod === "POST") {
                    postItem.like_count++
                    postItem.auth_user_likes = 1
                }
                else {
                    postItem.like_count--
                    postItem.auth_user_likes = 0
                }
            }

            setGlobalData({ ...globalData, allPosts: posts })
            setLikeLoading(false)

        }
    }

    const deletePost = async () => {
        setDeleteLoading(true)
        let response
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/post`,
                {
                    method: "DELETE",
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    },
                    body: JSON.stringify({
                        post_id: postItem.post_id
                    })
                })
        }
        catch (e) {
            console.error(e)

            toast.error("Failed to delete post", {
                autoClose: 2000,
                hideProgressBar: true
            })
            setDeleteLoading(false)
        }

        if (!response.ok) {
            if (response.status === 429) {
                const error = await response.text();
                console.log(error)
                toast.error(`${error}`, {
                    autoClose: 1000,
                    hideProgressBar: true
                })
                setDeleteLoading(false)
                return
            }
            const error = await response.json()
            console.error(error)

            toast.error(error.message, {
                autoClose: 2000,
                hideProgressBar: true
            })
            setDeleteLoading(false)
        }
        else {
            const data = await response.json()

            let posts = globalData.allPosts

            console.log(posts)
            //since only auth user can delete their own posts, and their own posts only show up on the profilemain page no need to 
            //loop through all the keys (each dif page like /feed) and remove the post their too bc their post won't ever show up there
            if (globalData.allPosts[authData.firebaseuser?.displayName]) {
                posts[authData.firebaseuser?.displayName] = posts[authData.firebaseuser?.displayName].filter((item, index) => {
                    return !(item.post_id === postItem.post_id)
                })
            }

            if (fromPostPage) {
                setIsDeleted(true)
            }

            setGlobalData({ ...globalData, allPosts: posts })
            setDeleteLoading(false)

        }
    }

    const copyPostLinkToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(`https://www.bloomtrades.net/post/${postItem.post_id}`);
            toast.success("Copied shareable link to clipboard!", {
                autoClose: 2000,
                hideProgressBar: true
            })
        } catch (err) {
            console.error(err);
            toast.error("Failed to copy shareable link to clipboard", {
                autoClose: 2000,
                hideProgressBar: true
            })
        }
    }

    const reportPost = async () => {
        let response
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/reportpost`,
                {
                    method: "POST",
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    },
                    body: JSON.stringify({
                        post_id: postItem.post_id
                    })
                })
        }
        catch (e) {
            console.error(e)

            toast.error("Failed to report post", {
                autoClose: 2000,
                hideProgressBar: true
            })

        }
        if (!response.ok) {
            if (response.status === 429) {
                const error = await response.text();
                console.error(error)
                toast.error(`${error}`, {
                    autoClose: 1000,
                    hideProgressBar: true
                })
                return
            }
            const error = await response.json()
            console.error(error)
            toast.error(error.message, {
                autoClose: 2000,
                hideProgressBar: true
            })
        }
        else {
            const data = await response.json()
            toast.success(data.message, {
                autoClose: 2000,
                hideProgressBar: true
            })
        }
    }

    const reply = () => {
        if (authData.firebaseuser) {
            setReplyModal(true)
        }
        else {
            toast.error("Must be signed in to reply to posts", {
                autoClose: 2000,
                hideProgressBar: true
            })
            return
        }
    }

    return useMemo(() => {
        if (isDeleted) {
            return <VStack mx={'auto'} alignItems='center' className="cardStackst" fontFamily="Futura">
                <Flex width='100%' padding={5} flexDirection="row" borderWidth={1} borderRadius={10} mt={2} justifyContent='center'>
                    <Text fontWeight='700'>Post was deleted</Text>
                </Flex>
            </VStack>
        }

        return (
            <ListItem bg='#212121' pointerEvents={closeLoading || deleteLoading || averageLoading ? "none" : "auto"} position='relative' opacity={closeLoading || deleteLoading || averageLoading ? 0.5 : 1} _hover={{ cursor: "pointer" }} borderWidth={1} borderRadius={10} mb={2} width="100%">
                <Flex padding={5} paddingBottom={3} flexDirection="row">
                    <Link to={authData.firebaseuser?.displayName === postItem.username ? "/profile" : `/user/${postItem.username}`}>
                        <Avatar bg='' src={postItem.username === authData.firebaseuser?.displayName ? authData.firebaseuser?.photoURL : postItem.avatar} />
                    </Link>
                    <Stack spacing={0} marginLeft={5} width={isSmallScreen ? '100%' : '100%'} overflowX='auto'>
                        <Link to={`/post/${postItem.post_id}`} >

                            <Flex direction='row' alignItems='center'>
                                <Flex direction='row' alignItems='center' textAlign='center'>
                                    <Text as='b'>{postItem.username}</Text>
                                    {
                                        postItem.user_verified ? <Icon as={MdVerified} color='#949ffa' ml={0.5} boxSize={5} /> : null
                                    }
                                </Flex>
                                <Spacer />
                                <Text as='b' fontSize='sm' opacity={0.5}>
                                    {differenceInDays(postItem.created_at * 1000)} ago
                            </Text>
                            </Flex>

                            <ReplyModal setReply={setReply} replies={replies} fromPostPage={fromPostPage} isSmallScreen={isSmallScreen} userPost={postItem.username} postItem={postItem} isOpen={replyModal} setIsOpen={(e) => setReplyModal(e)} postID={postItem.post_id} />

                            <Flex flexDirection='row' pt={2} pb={2}>
                                <Tag lineHeight={1} mr={1} colorScheme={postItem.is_free ? 'green' : 'red'} size='sm'> <Icon as={postItem.is_free ? FiUnlock : FiLock} /> <Text as='b'></Text></Tag>
                                {
                                    postItem.sentiment !== null ? <Tag lineHeight={1} fontWeight="bold" mr={1} colorScheme={postItem.sentiment === "Bearish" ? "red" : "green"} size='sm'><Text mr={1} as='b'>{postItem.sentiment}</Text> <Icon as={postItem.sentiment === "Bearish" ? FiTrendingDown : FiTrendingUp} /></Tag> : null
                                }

                                {
                                    postItem.closed_percent !== null ? <Tag lineHeight={1} fontWeight="bold" mr={1} colorScheme={postItem.closed_percent === 0 ? 'gray' : (postItem.closed_percent > 0 ? 'green' : 'red')} size='sm'>{postItem.closed_percent.toFixed(2)}%</Tag> : null
                                }

                                {
                                    postItem.is_open ? <Tag lineHeight={1} mr={1} colorScheme='teal' size='sm'> <Icon mr={1} as={FiEye} /> <Text as='b'>Open</Text></Tag> : null
                                }

                                <Spacer />

                                {
                                    postItem.is_closed ? <Tag lineHeight={1} colorScheme={closeState[postItem.close_state].color} size='sm'> <Text mr={1} as='b'>{postItem.close_state}</Text> <Icon as={closeState[postItem.close_state].icon} /></Tag> : null
                                }

                            </Flex>

                            {postItem.content.trim().length > 0 ?
                                <Box style={{ whiteSpace: 'pre-wrap' }} fontFamily='Futura' as='b' width="100%">
                                    <Text pt={1} opacity={0.9}>
                                        {postItem.content}
                                    </Text>
                                </Box> : null
                            }

                        </Link>
                        {

                            postItem.image_url ? <Flex pb={postItem.alert_price && postItem.symbol ? 2 : 2}> <Box height="200px" overflow='hidden' mt={4}> <Image objectFit="contain" src={postItem.image_url} onClick={() => setIsOpen(true)} /> </Box> </Flex> : null

                        }

                        {
                            postItem.alert_price && postItem.symbol ? (
                                <Link to={postItem.is_crypto ? `/crypto/${postItem.crypto_id}` : `/symbol/${postItem.symbol}`} >
                                    <Flex flexDirection='row' as='b' pt={2}>
                                        {
                                            postItem.is_closed && postItem.is_tracked && !postItem.is_open && postItem.close_price !== null && (postItem.closed_percent !== null) ? (<>
                                                <Box display="flex" flexDirection="column">
                                                    <Text opacity={0.5} fontSize='sm' mr={2} noOfLines={1}>
                                                        {postItem.is_option ? `${postItem.symbol} ${postItem.strike_price}${postItem.option_type} ${postItem.option_expiry}` : `${postItem.symbol}`}
                                                    </Text>

                                                    <Box display="flex" alignItems="center">
                                                        <Text opacity={0.5} fontSize='sm' mr={2}>
                                                            ${postItem.is_crypto ? postItem.alert_price : postItem.alert_price.toLocaleString(undefined, { maximumFractionDigits: 4 })}
                                                        </Text>
                                                        <Icon
                                                            fontSize='xl'
                                                            color={postItem.closed_percent > 0 ? "green.200" : postItem.closed_percent === 0 ? "gray.200" : "red.200"}
                                                            as={postItem.closed_percent > 0 ? FiTrendingUp : postItem.closed_percent === 0 ? FiActivity : FiTrendingDown}
                                                        />
                                                        <Text opacity={0.5} fontSize='sm' ml={2}>
                                                            ${postItem.is_crypto ? postItem.close_price : postItem.close_price.toLocaleString(undefined, { maximumFractionDigits: 4 })}
                                                        </Text>
                                                    </Box>
                                                    <Box display="flex" mt={1} mb={1}>
                                                        <Icon as={FiClock} opacity={0.5} mr={1} fontSize='xl' />
                                                        <Text opacity={0.5} fontSize='sm'>
                                                            {differenceInDaysClose(postItem.created_at * 1000, postItem.closed_at * 1000)}
                                                        </Text>
                                                    </Box>
                                                </Box>

                                            </>
                                            ) : (
                                                    <>
                                                        <Text opacity={0.5} fontSize='sm' mr={2} noOfLines={1}>
                                                            {postItem.is_option ? `${postItem.symbol} ${postItem.strike_price}${postItem.option_type} ${postItem.option_expiry}` : `${postItem.symbol}`} · ${postItem.is_crypto ? postItem.alert_price : postItem.alert_price.toLocaleString(undefined, { maximumFractionDigits: 4 })}
                                                        </Text>
                                                    </>
                                                )
                                        }
                                    </Flex>
                                </Link>
                            ) : null
                        }

                        <Flex flexDirection='row' paddingBottom={2} pt={3} mx={'auto'} className="postBtn">

                            <Button leftIcon={<TriangleUpIcon />} variant='outline' size='xs' onClick={likeLoading ? null : likePost} _hover={{ color: '#949FFA' }} color={postItem.auth_user_likes !== 0 ? "#949FFA" : null}>
                                {formatLargeNumber(postItem.like_count)}
                            </Button>

                            <Button ml={2} leftIcon={<ChatIcon />} variant='outline' size='xs' onClick={reply} _hover={{ color: '#949FFA' }}>
                                {formatLargeNumber(postItem.reply_count)}
                            </Button>

                            <Spacer />

                            {
                                authData.firebaseuser?.displayName === postItem.username && postItem.is_open && !postItem.is_closed ? (
                                    <>
                                        <Tooltip label='Complete Prediction' placement='top'>
                                            <IconButton _hover={{ color: 'green.200' }} mr={2} minW='8' icon={<FiSend size='14' />} variant='outline' onClick={closePost} size='xs'></IconButton>
                                        </Tooltip>
                                        <Tooltip label='Average Price Up/Down' placement='top'>
                                            <IconButton _hover={{ color: 'orange.200' }} mr={2} minW='8' icon={<FiPercent size='14' />} variant='outline' onClick={averagePrice} size='xs'></IconButton>
                                        </Tooltip>
                                    </>
                                ) : null
                            }

                            <Menu>
                                <MenuButton lineHeight={1}>
                                    <IconButton minW='8' icon={<FiMoreHorizontal size='16' />} variant='outline' size='xs'></IconButton>
                                </MenuButton>
                                <Portal>
                                    <MenuList bgColor="#1C1C1C" position='relative'>
                                        {
                                            (authData.firebaseuser?.displayName === postItem.username && postItem.is_open && !postItem.is_closed) ? (<>
                                                <MenuItem color="green.200" icon={<FiSend size={20} />} backgroundColor='#1C1C1C' onClick={closePost}><Text fontFamily="Futura" as='b'>Complete Prediction</Text></MenuItem>
                                                <MenuDivider />
                                                <MenuItem color="orange.200" icon={<FiPercent size={20} />} backgroundColor='#1C1C1C' onClick={averagePrice}><Text fontFamily="Futura" as='b'>Average Price</Text></MenuItem>
                                                <MenuDivider />
                                            </>
                                            ) : null
                                        }
                                        {authData.firebaseuser !== null && authData.firebaseuser.displayName === postItem.username ? <> <MenuItem color="red.200" icon={<FiTrash2 size={20} />} backgroundColor='#1C1C1C' onClick={deletePost}><Text fontFamily="Futura" as='b'>Delete Post</Text></MenuItem> <MenuDivider /> </> : null}
                                        {authData.firebaseuser !== null && authData.firebaseuser.displayName !== postItem.username ? <> <MenuItem color="red.200" icon={<FiFlag size={20} />} bgColor="#1C1C1C" onClick={reportPost}><Text fontFamily="Futura" as='b'>Report</Text></MenuItem> <MenuDivider /> </> : null}
                                        <MenuItem icon={<FiCopy size={20} />} backgroundColor='#1C1C1C' onClick={copyPostLinkToClipboard}><Text fontFamily="Futura" as='b'>Share</Text></MenuItem>
                                    </MenuList>
                                </Portal>

                            </Menu>
                        </Flex>

                    </Stack>

                </Flex>


                <Modal size={isSmallScreen ? "lg" : "2xl"} isOpen={isOpen} onClose={() => setIsOpen(false)} >
                    <ModalOverlay />
                    <ModalContent  >
                        <img src={postItem.image_url} />
                        <ModalCloseButton />
                    </ModalContent>
                </Modal>
                {(closeLoading || deleteLoading || averageLoading) && (
                    <Box
                        position="absolute"
                        top="50%"
                        left="50%"
                        transform="translate(-50%, -50%)"
                        zIndex="1000"
                    >
                        <Spinner size="lg" />
                    </Box>
                )}

            </ListItem>
        )
        //}, [postItem.lk, isOpen, replyIsOpen, isSmallScreen, postItem.is_closed, closeLoading])
    }, [JSON.stringify(postItem), replyModal, isOpen, isSmallScreen, closeLoading, authData.firebaseuser?.photoURL, deleteLoading, averageLoading, likeLoading, differenceInDays(postItem.created_at * 1000)])

}

export default Posts