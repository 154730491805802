import {
    AreaChart,
    Area,
    YAxis,
    ResponsiveContainer,
    ReferenceLine,
    Tooltip,
    ReferenceDot
} from "recharts";
import {
    Flex,
    Text,
    Heading,
    IconButton,
    RadioGroup,
    Stack,
    Button,
    Spinner,
    Tag,
    Image
} from '@chakra-ui/react';
import { useEffect, useState, useRef } from 'react';
import { FiRefreshCcw } from "react-icons/fi";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SymbolChart = ({ symbol, fromPost, alertTime, closeTime }) => {

    const timeRanges = ["1d", "5d", "1mo", "6mo", "ytd", "5y", "max"];

    const ratio = {
        "1d": "1m",
        "5d": "5m",
        "1mo": "15m",
        "6mo": "60m",
        "ytd": "60m",
        "1y": "1d",
        "5y": "5d",
        "max": "1wk"
    }

    const timeObj = {
        timeZone: "America/New_York",
        hour: '2-digit',
        minute: '2-digit',
        month: 'short',
        timeZoneName: 'short',
        year: 'numeric',
        day: 'numeric'
    }
    const getMinMax = (arr) => {
        let min = arr[0].quote;
        let max = arr[0].quote;

        for (let i = 1; i < arr.length; i++) {
            if (arr[i].quote < min) {
                min = arr[i].quote;
            }
            if (arr[i].quote > max) {
                max = arr[i].quote;
            }
        }
        return [min, max]
    }

    const calcDifference = () => {
        return `${quote - chartData.prevClose >= 0 ? "+" : ""}${(quote - chartData.prevClose).toFixed(quote < 5 ? 4 : 2)}`
    }

    const calculatePercentDifference = () => {
        return `${quote - chartData.prevClose >= 0 ? "+" : ""}${(((quote - chartData.prevClose) / chartData.prevClose) * 100).toFixed(2)}%`
    }
    const [chartData, setChartData] = useState([])

    const [quote, setQuote] = useState(0)
    const [timestamp, setTimestamp] = useState(0)
    const [isIncrease, setIncreased] = useState(false)

    const [range, setRange] = useState([0, 0])
    const chartRef = useRef(null);
    const [refreshLoading, setRefreshLoading] = useState(false)

    const [selectedRange, setSelectedRange] = useState("5d")

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const getChartData = async () => {
            setLoading(true)
            let response
            try {
                response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/symbol/price?symbol=${symbol}&range=${selectedRange}&interval=${ratio[selectedRange]}`,
                    {
                        method: "GET",
                        headers: {
                            "ngrok-skip-browser-warning": "any",
                            Accept: "application/json",
                            "Content-Type": "application/json"
                        }
                    })
            }
            catch (e) {
                console.error(e)
                toast.error(`Failed to retrieve data for '${symbol}'`, {
                    autoClose: 2000,
                    hideProgressBar: true
                })
                setChartData(null)
                setLoading(false)
                return
            }

            if (!response.ok) {
                if (response.status === 429) {
                    const error = await response.text();
                    console.error(error)
                    toast.error(`${error}`, {
                        autoClose: 2000,
                        hideProgressBar: true
                    })
                    setLoading(false)
                    setChartData(null)
                    return
                }
                const error = await response.json()
                console.error(error)
                toast.error(error.message, {
                    autoClose: 2000,
                    hideProgressBar: true
                })
                setChartData(null)
            }
            else {
                const data = await response.json()
                setChartData(data.data)
                setRange(getMinMax(data.data.chartData))
                setTimestamp(new Date(data.data.chartData[data.data.chartData.length - 1].timestamp * 1000).toLocaleString('en-US', { ...timeObj }))
                const nQuote = data.data.chartData[data.data.chartData.length - 1].quote.toFixed(data.data.chartData[data.data.chartData.length - 1].quote < 5 ? 4 : 2)
                setQuote(nQuote)
                setIncreased(+(data.data.prevClose.toFixed(nQuote < 5 ? 4 : 2)) > +(data.data.chartData[data.data.chartData.length - 1].quote.toFixed(nQuote < 5 ? 4 : 2)) ? false : true)
            }
            setLoading(false)
        }

        getChartData()


    }, [symbol])

    const refreshPrice = async () => {
        setRefreshLoading(true)
        let response
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/symbol/price?symbol=${symbol}&range=${selectedRange}&interval=${ratio[selectedRange]}`,
                {
                    method: "GET",
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                })
        }
        catch (e) {
            console.error(e)
            toast.error(`Failed to retrieve data for '${symbol}'`, {
                autoClose: 2000,
                hideProgressBar: true
            })
            setRefreshLoading(false)
            return
        }

        if (!response.ok) {
            if (response.status === 429) {
                const error = await response.text();
                console.error(error)
                toast.error(`${error}`, {
                    autoClose: 2000,
                    hideProgressBar: true
                })
                setRefreshLoading(false)
                return
            }
            const error = await response.json()
            console.error(error)
            toast.error(error.message, {
                autoClose: 2000,
                hideProgressBar: true
            })
        }
        else {
            const data = await response.json()
            setChartData(data.data)
            setRange(getMinMax(data.data.chartData))
            setTimestamp(new Date(data.data.chartData[data.data.chartData.length - 1].timestamp * 1000).toLocaleString('en-US', { ...timeObj }))
            const nQuote = data.data.chartData[data.data.chartData.length - 1].quote.toFixed(data.data.chartData[data.data.chartData.length - 1].quote < 5 ? 4 : 2)
            setQuote(nQuote)
            setIncreased(+(data.data.prevClose.toFixed(nQuote < 5 ? 4 : 2)) > +(data.data.chartData[data.data.chartData.length - 1].quote.toFixed(nQuote < 5 ? 4 : 2)) ? false : true)
        }
        setRefreshLoading(false)
    }

    const newData = async (range) => {
        const oldRange = selectedRange
        if (range === selectedRange) {
            return
        }
        setSelectedRange(range)
        setRefreshLoading(true)
        let response
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/symbol/price?symbol=${symbol}&range=${range}&interval=${ratio[range]}`,
                {
                    method: "GET",
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                })
        }
        catch (e) {
            console.error(e)
            toast.error(`Failed to retrieve data for '${symbol}'`, {
                autoClose: 2000,
                hideProgressBar: true
            })
            setSelectedRange(oldRange)
            setRefreshLoading(false)
            return
        }

        if (!response.ok) {
            if (response.status === 429) {
                const error = await response.text();
                console.error(error)
                toast.error(`${error}`, {
                    autoClose: 2000,
                    hideProgressBar: true
                })
                setSelectedRange(oldRange)
                setRefreshLoading(false)
                return
            }
            const error = await response.json()
            console.log(error)
            toast.error(error.message, {
                autoClose: 2000,
                hideProgressBar: true
            })
            setSelectedRange(oldRange)
            setRefreshLoading(true)
        }
        else {
            const data = await response.json()
            setChartData(data.data)
            setRange(getMinMax(data.data.chartData))
            setTimestamp(new Date(data.data.chartData[data.data.chartData.length - 1].timestamp * 1000).toLocaleString('en-US', { ...timeObj }))
            const nQuote = data.data.chartData[data.data.chartData.length - 1].quote.toFixed(data.data.chartData[data.data.chartData.length - 1].quote < 5 ? 4 : 2)
            setQuote(nQuote)
            setIncreased(+(data.data.prevClose.toFixed(nQuote < 5 ? 4 : 2)) > +(data.data.chartData[data.data.chartData.length - 1].quote.toFixed(nQuote < 5 ? 4 : 2)) ? false : true)
        }
        setRefreshLoading(false)
    }

    if (loading) {
        return <Flex bg='#212121' width='100%' flexDir='column' padding={5} borderWidth={1} borderRadius={10} height="600px" align="center" justify="center">
            <Spinner thickness='2px' speed='1s' size='lg' color='#949FFA' />
        </Flex>
    }

    if (!chartData) {
        return <Flex bg='#212121' width='100%' flexDir='column' padding={5} borderWidth={1} borderRadius={10} height="600px" align="center" justify="center">
            <Text as='b'> No chart data for '{symbol}'</Text>
        </Flex>
    }

    return (
        <Flex bg='#212121' width='100%' flexDir='column' padding={5} borderWidth={1} borderRadius={10} height="600px" >
            <Heading fontFamily="Futura" size="lg" display="flex" alignItems="center">
                <Image
                    src={`https://raw.githubusercontent.com/davidepalazzo/ticker-logos/refs/heads/main/ticker_icons/${symbol.toUpperCase().replaceAll(".TO", "")}.png`}
                    alt={symbol}
                    boxSize="1.5em" // Adjust this size to match the heading
                    mr={2} // Adds some space between the image and the text
                    onError={(e) => (e.target.style.display = 'none')} // Hide the image if loading fails
                />
                {symbol.toUpperCase()}
                <IconButton ml={1} isLoading={refreshLoading} variant="noHover" _hover={{ "@media (hover: none)": { bg: "transparent" } }} transform="translate3d(0,0,0)" onClick={refreshPrice} color='#949FFA' size='xs' icon={<FiRefreshCcw fontSize='18' />}
                />
            </Heading>
            <Flex alignItems="baseline">
                <Text fontWeight={700} fontSize='2xl' color={Number(quote) === chartData.prevClose ? "#E2E8F0" : (Number(quote) > chartData.prevClose ? "#9AE6B4" : "#FEB2B2")}>
                    ${quote}
                </Text>
                <Tag mr={1} ml={2} fontWeight="bold" size='sm' colorScheme={Number(quote) === chartData.prevClose ? "gray" : (Number(quote) > chartData.prevClose ? "green" : "red")}>
                    {calcDifference()}
                </Tag>
                <Tag fontWeight="bold" size='sm' colorScheme={Number(quote) === chartData.prevClose ? "gray" : (Number(quote) > chartData.prevClose ? "green" : "red")}>
                    {calculatePercentDifference()}
                </Tag>

            </Flex>
            <Text mt={1} as='b' fontSize='sm' opacity={0.85}>
                {timestamp}
            </Text>

            <ResponsiveContainer height="90%" width="100%">
                <AreaChart data={chartData.chartData}
                    onMouseMove={(e) => {
                        setQuote(e.activePayload ? e.activePayload[0].payload.quote.toFixed(quote < 5 ? 4 : 2) : chartData.chartData[chartData.chartData.length - 1].quote.toFixed(quote < 5 ? 4 : 2))
                        setTimestamp(e.activePayload ? new Date(e.activePayload[0].payload.timestamp * 1000).toLocaleString('en-US', { ...timeObj }) : new Date(chartData.chartData[chartData.chartData.length - 1].timestamp * 1000).toLocaleString('en-US', { ...timeObj }))
                    }}

                    onMouseLeave={() => {
                        setTimestamp(new Date(chartData.chartData[chartData.chartData.length - 1].timestamp * 1000).toLocaleString('en-US', { ...timeObj }))
                        setQuote(chartData.chartData[chartData.chartData.length - 1].quote.toFixed(quote < 5 ? 4 : 2))
                    }}

                    onMouseUp={(e) => {
                        chartRef.current.handleItemMouseLeave()
                        setTimestamp(new Date(chartData.chartData[chartData.chartData.length - 1].timestamp * 1000).toLocaleString('en-US', { ...timeObj }))
                        setQuote(chartData.chartData[chartData.chartData.length - 1].quote.toFixed(quote < 5 ? 4 : 2))
                    }}
                    ref={chartRef}

                >
                    <YAxis ticks={range} type="number" domain={range} width={0} tickLine={false} axisLine={false} tick={false} />
                    <Tooltip content={<CustomTooltip />} />

                    <Area type="monotone" dataKey="quote" stroke="#E2E8F0" stroke={isIncrease ? "#9AE6B4" : '#FEB2B2'} strokeWidth={2} fill="#212121" />
                    <ReferenceLine y={chartData.prevClose} dx={60} stroke="#5A5A5A" strokeDasharray="2 5" />
                    {
                        fromPost ? <ReferenceDot x={findClosestIndex(chartData.chartData, alertTime)?.x} y={findClosestIndex(chartData.chartData, alertTime)?.y} r={5} fill="#949ffa" stroke="white" strokeWidth={2}/> : null
                    }
                    {
                        fromPost ? <ReferenceDot x={findClosestIndex(chartData.chartData, closeTime)?.x} y={findClosestIndex(chartData.chartData, closeTime)?.y} r={5} fill="#949ffa" stroke="white" strokeWidth={2}/> : null
                    }

                </AreaChart>


            </ResponsiveContainer>

            <RadioGroup mt={2}>
                <Stack direction="row" spacing={2} justifyContent='center'>
                    {timeRanges.map((range) => (
                        <Button
                            isDisabled={refreshLoading}
                            borderRadius={10}
                            size='sm'
                            key={range}
                            _hover={{ bg: selectedRange === range ? "#949ffa" : "#2A2A2A", opacity: selectedRange === range ? 1 : 0.85 }}
                            bg={selectedRange === range ? "#949ffa" : ""}
                            color={selectedRange === range ? "#212121" : ""}
                            opacity={selectedRange === range ? 1 : 0.85}
                            onClick={() => newData(range)}>
                            {range}
                        </Button>
                    ))}
                </Stack>
            </RadioGroup>
        </Flex>

    )
}

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div>

            </div>
        );
    }

    return null;
};

const findClosestIndex = (chartData, targetTimestamp) => {
    if (!chartData || chartData.length === 0) {
        return -1; // Return -1 if data is empty or undefined
    }

    let closestIndex = 0;
    let minDifference = Math.abs(chartData[0].timestamp - targetTimestamp);

    for (let i = 1; i < chartData.length; i++) {
        const currentDifference = Math.abs(chartData[i].timestamp - targetTimestamp);

        if (currentDifference < minDifference) {
            closestIndex = i;
            minDifference = currentDifference;
        }
    }
    if (closestIndex === 0) return null
    return {
        x: closestIndex,
        y: chartData[closestIndex].quote
    }
}

export default SymbolChart