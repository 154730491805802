import {
    ChakraProvider,
    Box,
    Heading,
    Button,
    VStack,
    Flex,
    Text,
    useMediaQuery,
    Spacer,
    HStack,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { AuthContext } from "./App.js"
import { Helmet } from "react-helmet"
import { MdAutoGraph, MdOutlineLockPerson, MdMarkEmailUnread } from "react-icons/md";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { SiNvidia, SiAmazon, SiMeta, SiMicrosoft, SiNetflix, SiUber, SiShopify, SiTesla, SiAmd, SiRobinhood, SiPalantir, SiSnapchat, SiApple, SiCoinbase, SiIntel } from 'react-icons/si'; // Import icons as needed
import { TbBrandDisney } from "react-icons/tb";
import { RiVisaLine } from "react-icons/ri";
import { FcGoogle } from "react-icons/fc";
import { LuBadgePercent } from "react-icons/lu";

const logos = [
    <SiApple fontSize='48' color='#959595' />,
    <SiNvidia fontSize='48' color='#74B71C' />,
    <SiAmazon fontSize='48' color='#FF9900' />,
    <FcGoogle fontSize='48' />,
    <SiMeta fontSize='48' color='#0173D5' />,
    <SiMicrosoft fontSize='48' color='#00AEEF' />,
    <SiNetflix fontSize='48' color='#E50A14' />,
    <SiAmd fontSize='48' color='#049E6B' />,
    <SiTesla fontSize='48' color='#E92A30' />,
    <SiUber fontSize='48' color='#FFFFFF' />,
    <SiShopify fontSize='48' color='#9AC14D' />,
    <RiVisaLine fontSize='48' color='#045EA2' />,
    <SiCoinbase fontSize='48' color='#0457FE' />,
    <SiRobinhood fontSize='48' color='#04CA0F' />,
    <SiPalantir fontSize='48' color='#FCFCFC' />,
    <SiSnapchat fontSize='48' color='#FFF219' />,
    <TbBrandDisney fontSize='48' color='#2393EA' />,
    <SiIntel fontSize='48' color='#0476C6' />,
];
const logoPaths = ["AAPL", "NVDA", "AMZN", "GOOGL", "META", "MSFT", "NFLX", "AMD", "TSLA", "UBER", "SHOP", "V", "COIN", "HOOD", "PLTR", "SNAP", "DIS", "INTC"];

const LandingPage = () => {
    const [isSmallScreen] = useMediaQuery("(max-width: 820px)");
    const navigate = useNavigate();
    const [authData, setAuthData] = useContext(AuthContext)

    const scrollableDiv = document.getElementById('scrollableDiv');
    if (scrollableDiv) {
      scrollableDiv.scrollTo(0, 0);
    }

    useEffect(() => {
        if (authData.firebaseuser) {
            navigate("/feed")
        }
    }, [authData])

    if (authData.firebaseuser) {
        return <> </>
    }
    
    return (
        <ChakraProvider>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Bloomtrades</title>
                <link rel="canonical" href="https://www.bloomtrades.net" />
                <meta name="description" content="Access free market predictions and turn your insights into earnings" />
            </Helmet>
            <Box
                textAlign="center"
                borderRadius="lg"
                className="cardStackst"
                mx="auto"
                mt="5"
                mb={5}
            >

                <Heading fontSize="4xl" mb="8" fontFamily="Futura" fontWeight="700">
                    Access <Text as="span" color="#949FFA">free</Text> stock market & crypto predictions
                    · Monetize your insights now to start <Text as="span" color="#949FFA">earning</Text>
                </Heading>
                <VStack spacing="4">
                    <Button
                        fontWeight="700"
                        size="md"
                        width="200px"
                        borderRadius={10}
                        bg={'#949ffa'}
                        color="#1C1C1C"
                        _hover={{ bg: "#949ffa", color: '#1C1C1C' }}
                        onClick={() => navigate("/signin")}
                    >
                        Sign In
                    </Button>
                    <Button
                        fontWeight="700"
                        size="md"
                        width="200px"
                        borderRadius={10}
                        variant="outline"
                        _hover={{ bg: "#949ffa", color: '#1C1C1C' }}
                        onClick={() => navigate("/signup")}
                    >
                        Sign Up
                    </Button>

                    <Flex direction='column' width='100%'>
                        <Heading fontSize="2xl" fontFamily="Futura" fontWeight="700" mx='0' textAlign="left">
                            Popular Discussions
                        </Heading>
                        <HStack spacing={3} overflowX="auto" width='100%' pt={3} sx={{ scrollbarWidth: 'none', '::-webkit-scrollbar': { display: 'none' } }}>
                            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17].map((data, index) => (
                                <Link to={`/symbol/${logoPaths[index]}`} key={logoPaths[index]}>
                                    <Box bg='#212121' width='100%' borderWidth="1px" borderRadius="lg">
                                        <Flex p='6'>
                                            {logos[index]} {/* amazon, google, meta, microsoft, google, netflix, uber, spy, shopify, tesla, amd, disney, robinhood, palantir, ford, snapchat,  */}
                                        </Flex>
                                    </Box>
                                </Link>
                            ))}
                        </HStack>
                    </Flex>

                    {
                        !isSmallScreen ? (<Flex direction='column' pt={1}>
                            <Flex direction='row'>
                                <Box bg='#212121' width='49%' borderWidth="1px" borderRadius="lg" overflow="hidden">
                                    <Flex justifyContent="center" mt={5}>
                                        <MdOutlineLockPerson fontSize='92' color='#949FFA' />
                                    </Flex>
                                    <Box p="6">
                                        <Text fontFamily="Futura" fontWeight="bold" fontSize="lg" mt="1" textAlign="center">
                                            Access exclusive discussions and analysis from the top traders in the finance space
                                        </Text>
                                    </Box>
                                </Box>
                                <Spacer />
                                <Box bg='#212121' width='49%' borderWidth="1px" borderRadius="lg" overflow="hidden">
                                    <Flex justifyContent="center" mt={5}>
                                        <MdAutoGraph fontSize='92' color='#949FFA' />
                                    </Flex>
                                    <Box p="6">
                                        <Text fontFamily="Futura" fontWeight="bold" fontSize="lg" mt="1" textAlign="center">
                                            Track your favourite stocks/crypto and create predictions to share with the community
                                        </Text>
                                    </Box>
                                </Box>
                            </Flex>
                            <Flex direction='row' mt={3}>
                                <Box bg='#212121' width='49%' borderWidth="1px" borderRadius="lg" overflow="hidden">
                                    <Flex justifyContent="center" mt={5}>
                                        <FaMoneyBillTransfer fontSize='92' color='#949FFA' />
                                    </Flex>
                                    <Box p="6">
                                        <Text fontFamily="Futura" fontWeight="bold" fontSize="lg" mt="1" textAlign="center">
                                            Join within minutes and monetize your financial insights, with the lowest platform fees of 20%
                                        </Text>
                                    </Box>
                                </Box>
                                <Spacer />
                                <Box bg='#212121' width='49%' borderWidth="1px" borderRadius="lg" overflow="hidden">
                                    <Flex justifyContent="center" mt={5}>
                                        <MdMarkEmailUnread fontSize='92' color='#949FFA' />
                                    </Flex>
                                    <Box p="6">
                                        <Text fontFamily="Futura" fontWeight="bold" fontSize="lg" mt="1" textAlign="center">
                                            Receive real time alerts directly to your email for predictions, with exact entry and exit prices!
                                        </Text>
                                    </Box>
                                </Box>
                            </Flex>
                            <Flex direction='row' mt={3}>
                                <Box bg='#212121' width='100%' borderWidth="1px" borderRadius="lg" overflow="hidden">
                                    <Flex justifyContent="center" mt={5}>
                                        <LuBadgePercent fontSize='92' color='#949FFA' />
                                    </Flex>
                                    <Box p="6">
                                        <Text fontFamily="Futura" fontWeight="bold" fontSize="lg" mt="1" textAlign="center">
                                            All users predictions are tracked and visible ensuring 100% transparency
                                        </Text>
                                    </Box>
                                </Box>
                            </Flex>
                            

                            <Box mt={2}>
                                <Text fontFamily="Futura" textAlign="center" fontSize='12'>
                                *Content generated on this platform is not financial advice and should not be taken as investment advice, for informational purposes only. Users should conduct their own research and due diligence. Learn more in our 
                                    <Link to="/terms-and-conditions">
                                        <Text as="span" color={'#949FFA'}> Terms and Conditions</Text>
                                    </Link>
                                </Text>
                            </Box>
                        </Flex>

                        ) : null
                    }
                    {
                        isSmallScreen ? (
                            <Flex direction='column' pt={1}>
                                <Box bg='#212121' width='100%' borderWidth="1px" borderRadius="lg" overflow="hidden">
                                    <Flex justifyContent="center" mt={5}>
                                        <MdOutlineLockPerson fontSize='92' color='#949FFA' />
                                    </Flex>
                                    <Box p="6">
                                        <Text fontFamily="Futura" fontWeight="bold" fontSize="lg" mt="1" textAlign="center">
                                            Access exclusive discussions and analysis from the top traders in the finance space
                                        </Text>
                                    </Box>
                                </Box>
                                <Box bg='#212121' mt={2} width='100%' borderWidth="1px" borderRadius="lg" overflow="hidden">
                                    <Flex justifyContent="center" mt={5}>
                                        <MdAutoGraph fontSize='92' color='#949FFA' />
                                    </Flex>
                                    <Box p="6">
                                        <Text fontFamily="Futura" fontWeight="bold" fontSize="lg" mt="1" textAlign="center">
                                            Track your favourite stocks/crypto and create predictions to share with the community
                                        </Text>
                                    </Box>
                                </Box>
                                <Box bg='#212121' mt={2} width='100%' borderWidth="1px" borderRadius="lg" overflow="hidden">
                                    <Flex justifyContent="center" mt={5}>
                                        <FaMoneyBillTransfer fontSize='92' color='#949FFA' />
                                    </Flex>
                                    <Box p="6">
                                        <Text fontFamily="Futura" fontWeight="bold" fontSize="lg" mt="1" textAlign="center">
                                            Join within minutes and monetize your financial insights, with the lowest platform fees of 20%
                                        </Text>
                                    </Box>
                                </Box>
                                <Box bg='#212121' mt={2} width='100%' borderWidth="1px" borderRadius="lg" overflow="hidden">
                                    <Flex justifyContent="center" mt={5}>
                                        <MdMarkEmailUnread fontSize='92' color='#949FFA' />
                                    </Flex>
                                    <Box p="6">
                                        <Text fontFamily="Futura" fontWeight="bold" fontSize="lg" mt="1" textAlign="center">
                                            Receive real time alerts directly to your email for predictions, with exact entry and exit prices!
                                        </Text>
                                    </Box>
                                </Box>
                                <Box mt={3} bg='#212121' width='100%' borderWidth="1px" borderRadius="lg" overflow="hidden">
                                    <Flex justifyContent="center" mt={5}>
                                        <LuBadgePercent fontSize='92' color='#949FFA' />
                                    </Flex>
                                    <Box p="6">
                                        <Text fontFamily="Futura" fontWeight="bold" fontSize="lg" mt="1" textAlign="center">
                                            All users predictions are tracked and visible ensuring 100% transparency
                                        </Text>
                                    </Box>
                                </Box>
                                <Box mt={2}>
                                    <Text fontFamily="Futura" textAlign="center" fontSize='12'>
                                        *Content generated on this platform is not financial advice and should not be taken as investment advice, for informational purposes only. Users should conduct their own research and due diligence. Learn more in our 
                                        <Link to="/terms-and-conditions">
                                            <Text as="span" color={'#949FFA'}> Terms and Conditions</Text>
                                        </Link>
                                    </Text>
                                </Box>
                            </Flex>
                        ) : null
                    }


                </VStack>
            </Box>
        </ChakraProvider>
    );
};

export default LandingPage;
