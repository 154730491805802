import {
    Box,
    Icon,
    Button,
    useMediaQuery
} from '@chakra-ui/react';
import { Routes, Route, useParams, Link } from "react-router-dom"

import MobileNav from "./MobileNav"
import Signin from '../Signin/Signin';
import Signup from '../Signup/Signup';
import ProfileMain from '../Profile/ProfileMain';

import PostPage from '../PostPage/PostPage';

import { useRef, useContext, useState } from 'react';
import ProfileMainSelf from '../Profile/ProfileMainSelf';
import Followers from '../Profile/Followers';
import Followings from '../Profile/Followings';
import Feed from '../Feed/Feed'
import SymbolFeed from '../SymbolFeed/SymbolFeed';
import Leaderboard from '../Leaderboard/Leaderboard';
import SearchGlobal from '../Search/SearchGlobal';
import Notification from "../Notification/Notification"
import InvalidPage from '../InvalidPage';
import LandingPage from '../LandingPage';
import AccountAction from '../AccountAction';
import Privacypolicy from "../Privacypolicy"
import TermsConditions from "../TermsConditions"
import Info from "../Info"
import { FiMail, FiPlusCircle } from 'react-icons/fi';
import { FaXTwitter, FaLinkedinIn } from 'react-icons/fa6';
import { AuthContext } from "../App.js"
import CreatePost from '../Profile/CreatePost';
import CryptoFeed from '../SymbolFeed/CryptoFeed';

//export const GlobalContext = createContext()

function SidebarWithHeader({ key }) {
    const ref = useRef(null);
    const [authData, setAuthData] = useContext(AuthContext)
    const [createPostModal, setCreatePostModal] = useState(false)
    const [isSmallScreen] = useMediaQuery("(max-width: 820px)");

    return (
        <Box fontFamily="Futura" ref={ref} width='100vw' height="100dvh" overflowX='hidden' bg='#1C1C1C' overflowY='auto' id="scrollableDiv" sx={{ scrollbarWidth: 'none', '::-webkit-scrollbar': { display: 'none' } }}>

            {/* mobilenav */}
            <MobileNav />
            <Box minH='100%'>


                <Routes>
                    <Route path="/signin" element={<Signin />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/profile" element={<ProfileMainSelf refM={ref} />} />
                    <Route path="/user/:username" element={<ProfileMain refM={ref} />} />
                    <Route path="/followers" element={<Followers refM={ref} />} />
                    <Route path="/following" element={<Followings refM={ref} />} />
                    <Route path="/feed" element={<Feed refM={ref} />} />
                    <Route path="/post/:postID" element={<PostPage refM={ref} />} />
                    <Route path="/symbol/:symbol" element={<SymbolFeedWrapper refM={ref} />} />
                    <Route path="/crypto/:crypto" element={<CryptoFeedWrapper refM={ref} />} />
                    <Route path="/leaderboards" element={<Leaderboard refM={ref} />} />
                    <Route path="/search" element={<SearchGlobal refM={ref} />} />
                    <Route path="/notifications" element={<Notification refM={ref} />} />
                    <Route path="/accountaction" element={<AccountAction />} />
                    <Route path="/privacy-policy" element={<Privacypolicy />} />
                    <Route path="/terms-and-conditions" element={<TermsConditions />} />
                    <Route path="/info" element={<Info />} />
                    <Route path="/" element={<LandingPage />} />
                    <Route path="*" element={<InvalidPage />} />

                    {
                        //IN ALL NEW PAGES DONT REQUIRE AUTH MAKE authData A DEPENDENCY FOR USEEFFECT - this is so when a dif tab signs out it will refetch the data as if they weren't signed in
                        /*
                        <Route path="/connections" element={<FollowersMain user={user} loggedUser={loggedUser} refM={ref}/>} />
                        <Route path="/payments" element={<Payment user={user} loggedUser={loggedUser} refM={ref} />} />
                        <Route path="/invoices" element={<Invoice user={user} loggedUser={loggedUser} refM={ref} />} />
                        <Route path="/notifications" element={<Notification user={user} loggedUser={loggedUser} refM={ref} />} />
                        <Route path="/search/users" element={<SearchForUser refM={ref} user={user} loggedUser={loggedUser} />} />
                        <Route path="/search/posts" element={<SearchForPost refM={ref} user={user} loggedUser={loggedUser} />} />
                        <Route path="/search/stocks" element={<Search refM={ref} user={user} loggedUser={loggedUser} />} />
                        <Route path="/settings" element={<Settings refM={ref} user={user} loggedUser={loggedUser} />} />
                        */
                    }

                </Routes>
            </Box>
            {
                authData.firebaseuser ? (
                    <CreatePost key={Math.random()} isOpen={createPostModal} setIsOpen={(e) => setCreatePostModal(e)} isSmallScreen={isSmallScreen} />
                ) : null
            }
            {
                authData.firebaseuser ? (
                    <Box position="fixed" bottom="4" right="4" boxShadow='2xl'>
                    <Button
                        onClick={() => setCreatePostModal(true)}
                        p="4"
                        size="md"
                        _hover={{bg: "#949FFA"}} 
                        color='#212121' 
                        bg='#949FFA'
                        leftIcon={<FiPlusCircle size='18' />} 
                        borderRadius={10}
                    >
                        Prediction
                    </Button>
                </Box>
                ) : null
            }

            <Box mt={20} style={{ bottom: 0, left: 0, width: '100%', background: '#1c1c1c', color: 'white', textAlign: 'center', padding: '20px' }} fontFamily='Futura'>
                <div style={{ marginBottom: '0px' }}>
                    <span style={{ fontWeight: 'bold', color: '#949FFA' }}>Bloomtrades, Inc</span> &copy; {new Date().getFullYear()} All rights reserved.
                    </div>
                <div style={{ marginBottom: '5px' }}>
                    <Link to="/privacy-policy" style={{ marginRight: '10px' }}>Privacy Policy</Link>
                    <Link to="/terms-and-conditions"> Terms and Conditions</Link>
                </div>
                <div>
                    <Link to="mailto:support@bloomtrades.net"> <Icon as={FiMail} mr={4} fontSize={22} /> </Link>
                    <Link to="https://twitter.com/BloomtradesNET" target="_blank"> <Icon as={FaXTwitter} mr={4} fontSize={22} /> </Link>
                    <Link to="https://www.linkedin.com/company/bloomtrades" target="_blank"> <Icon as={FaLinkedinIn} fontSize={22} /> </Link>
                </div>
            </Box>
        </Box>

    );
}

function SymbolFeedWrapper({ refM }) {
    const { symbol } = useParams(); // Extract the symbol parameter from the route

    return <SymbolFeed refM={refM} key={symbol} />
}

function CryptoFeedWrapper({ refM }) {
    const { crypto } = useParams(); // Extract the symbol parameter from the route

    return <CryptoFeed refM={refM} key={crypto} />
}

export default SidebarWithHeader